import React, { Component } from "react";
import isEqual from "lodash.isequal";
import Table from "./Table";
import PropTypes from "prop-types";
import { tableColums } from "../../config/TableViewConfig";
import withLogging from "../../logging/withLogging";
import TableFilter from "./TableFilter";

class TableBox extends Component {
  state = { showFilter: false, filterProperties: null, filters: {} };

  validate(props) {
    // Perform deep equality check before setting state
    if (!isEqual(this.state, props)) {
      this.setState({ ...props });
    }
  }

  componentDidMount() {
    this.validate(this.props);
    if(window.location.hash === '#vorsorgenkartei'){
      // console.log(localStorage.getItem("detailSort"));
      let statistik = "";
      if(localStorage.getItem("detailSort")){
        switch (localStorage.getItem("detailSort")) {
            case 'red':
                statistik = 'fällig';
                break;
            case 'yellow':
                statistik = "bald fällig"
                break;
            case 'green':
                statistik = "gültig"
                break;
        }
        this.setFilter({statistik: statistik})
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Use deep equality check for props and state
    if (!isEqual(prevProps, this.props) || !isEqual(prevState, this.state)) {
      this.validate(this.props);
    }
  }

  setFilter = (values) => {
    if (!isEqual(this.state.filters, values)) {
      this.setState({ filters: values }, () => console.log("setFilter state", this.state.filters));
    }
  };

  handleRowEdit = (id, resourceName = this.state.resourceName) => {
    // console.log("edit!", id, resourceName);
    global.emitter.emit("SHOW_MODAL" + (global.modal ? "_FALLBACK" : ""), {
      data: { resourceId: id, resourceName: resourceName, mode: 'bearbeiten' },
      onClose: () => {
        global.emitter.emit("REFRESH_TABLE_DATA");
      },
    });
  };

  onCreateNachuntersuchung = (data, resourceName = this.state.resourceName) => {
    // console.log("onNachuntersuchung", data, resourceName)
    global.emitter.emit("SHOW_MODAL" + (global.modal ? "_FALLBACK" : ""), {
      data: { resourceId: null, resourceName: resourceName, dataSet: data, mode: "erfassen" },
      onClose: () => {
        global.emitter.emit("REFRESH_TABLE_DATA");
      },
    });
  }


  shouldComponentUpdate(nextProps, nextState) {
    const propsChanged = !isEqual(this.props, nextProps);
    const stateChanged = !isEqual(this.state, nextState);

    return propsChanged || stateChanged;
  }

  render() {
    if (this.state.resourceName && this.state.schema) {
      return (
        <section className="col p-0 connectedSortable tableBox">
          <div className="align-items-center">
            <div className="row">
              <div className="card-tools col">
                {this.props.disableFilter && (
                  <TableFilter
                    filterFields={tableColums[this.state.resourceName] || []}
                    setFilter={this.setFilter}
                  />
                )}
              </div>
            </div>
            <div className="card-select-box row mt-3">
              <div className="col">{this.props.toolBox}</div>
            </div>
          </div>
          <div className="card table-card">
            <div
              className="card-body"
              style={{ maxWidth: "100%", overflowX: "auto" }}
            >
              {
                <Table
                  apiEndpoint={this.state.resourceName}
                  rowClicks={this.props.rowClicks ?? false}
                  filters={this.state.filters}
                  onRowEdit={this.handleRowEdit}
                  onNachuntersuchung={this.onCreateNachuntersuchung}
                  setCsvQuery={this.props.setCsvQuery}
                />
              }
            </div>
          </div>
        </section>
      );
    } else {
      return <span></span>;
    }
  }
}

TableBox.propTypes = {
  disableSearch: PropTypes.bool,
  disableFilter: PropTypes.bool,
  resourceName: PropTypes.string.isRequired,
  detailId: PropTypes.string,
  relatedResourceId: PropTypes.string,
  toolBox: PropTypes.object,
  rowClicks: PropTypes.bool,
  excludeFields: PropTypes.array,
  renderers: PropTypes.object,
};

TableBox.defaultProps = {
  disableSearch: true,
  disableFilter: true,
};

export default withLogging(TableBox);
