import React, { Component } from 'react';
import Sidebar from './pages/Sidebar';
import Header from './pages/Header';
import ContentHeader from './components/ContentHeader';
import Content from './pages/Content';
import TableBox from './components/Table/TableBox';
import LoginPage from './pages/LoginPage';
import ReportBox from './components/ReportBox';
import { EventEmitter } from 'events';
import mailingTriggers from "./components/AutoMailing/trigger/index";
import Modal from './components/Modal';
import { hashToSchema, schemaToForm } from './config/MenuConfig';
import Einstellungen from './pages/Einstellungen';
import ReportMitarbeiterBox from './components/ReportMitarbeiterBox';
import WidgetsArea from './components/Dashboard_Widgets/WidgetsArea';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import withLogging from './logging/withLogging';
import FormBox from './components/Forms/FormBox';

global.capitalize = (str) => {
  if (str) {
    const capitalized = str.split('_').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
    return capitalized;
  } else return "";
}

class App extends Component {
  static isOwnComponent = true;
  constructor(props) {
    super(props);
    this.schemas = { };
    this.csvExport = React.createRef();

    this.routes = {}
    global.auth = window.localStorage.getItem('auth') !== null ? JSON.parse(window.localStorage.getItem('auth')) : window.localStorage.getItem('auth');
    global.emitter = new EventEmitter();
    global.filterFirmenId = 0;
    if(process.env.NODE_ENV === "development"){
      global.api_url = process.env.REACT_APP_API_URL;
    } else {
      global.api_url = window._env_.REACT_APP_API_URL;
    }
    global.emitter.setMaxListeners(20);
  }


  state = {
  }

  async buildSchema() {
    if (global.auth) {
      //merken ob admin oder nicht
      global.isAdmin = (global.auth.user.rolle === "Admin") ? true : false ; 

      window.localStorage.getItem('ipp') !== null ? window.localStorage.getItem('ipp') : window.localStorage.setItem('ipp', 25);

      let definitions = await fetch(global.api_url + 'schema', { headers: { 'Content-Type': 'application/json', 'Authorization': global.auth.authToken.token } }).then(res => res.json());

      //split and extend on relational entity schemas
      let entity_names = Object.keys(definitions);
     //console.log('ohoho0', entity_names);
      for (let i = 0; i < entity_names.length; i++) {
        let resourceName = entity_names[i];
        this.schemas[resourceName] = definitions[resourceName];
        let property_names = Object.keys(this.schemas[resourceName].properties);
        for (let j = 0; j < property_names.length; j++) {
          let property_name = property_names[j];
          let property_schema = this.schemas[resourceName].properties[property_name];
          property_schema.title = global.capitalize(property_name)
        }
      }
    }
   //console.log('schemas', this.schemas);
  }

  async componentDidMount() {
  //  console.log('process:', process.env)
  //  console.log('window:', window._env_)

    //globaler Fetch Error Handling
    const originalFetch = window.fetch;
    window.fetch = async function (...args) {
      try {
        // Führen Sie die ursprüngliche Fetch-Anfrage aus
        const response = await originalFetch(...args);
        // Wenn die Antwort einen Fehlerstatus enthält, werfen Sie einen Fehler
        if (response.status === 419 || response.status === 401) throw new Error(`Fetch: ${response.status} ${response.statusText}`);
        return response;
      } catch (error) {
        // Behandeln Sie Fehler, die während der Fetch-Anfrage auftreten
        console.log(error.message)
        window.localStorage.removeItem('auth');
        window.location.hash = '';
        toast.info("Session abgelaufen, bitte neu Einloggen.", {
          position: "top-center",
        })
        window.location.reload();
        // Werfen Sie den Fehler erneut, damit der Aufrufer ihn behandeln kann
        throw error;
      }
    };

    global.emitter.on('REFRESH_SCHEMA', async () => {
      await this.buildSchema();
      this.forceUpdate();
    })
    await this.buildSchema();

    //Mailing Triggers
    for (const trigger in mailingTriggers) {
        global.emitter.on('TRIGGER_'+ trigger.toUpperCase(), (data) => {
          mailingTriggers[trigger].function(data);
        })
    }

    let checkHash = () => {
      if (window.location.hash !== '#') {
        var hash_uri = window.location.hash.substring(1).split('/');
        if(hash_uri[0] === "") window.location.hash = "dashboard";
        var new_hash_uri = hash_uri[0];
        global.hash = new_hash_uri;
        // console.log('new hash', global.hash);
        this.setState({
          hash_uri: new_hash_uri,
          hash_id: hash_uri[1]
        });
      }
    }
    window.addEventListener("hashchange", () => {
      let recall = false;
      setTimeout(() => {
        if (recall === false) {
          recall = true;
          checkHash();
        }
      }, 1000)
      global.emitter.emit('RESET', () => {
        if (recall === false) {
          recall = true;
          checkHash();
        }
      });
    }, false);
    checkHash();
    this.forceUpdate();

  }

  componentDidUpdate(){

  }

  setCsvQuery = (data) => {
    this.csvExport.current.setState({csvQuery: data})
  }

  changeContentTitle(title){
    // console.log("changeContentTitel", title);
    this.setState({contentHeaderTitel: title});
  }

  render() {
    let forms = [];
    Object.keys(this.schemas).forEach(resourceName => {
      // console.log('global.hash_uri', global.hash_uri);
     //console.log('state.hash_uri', this.state.hash_uri);
      if( (this.state.hash_uri in hashToSchema && hashToSchema[this.state.hash_uri].schemaResourceName === resourceName) ||
          resourceName === this.state.hash_uri){

        let schema = this.schemas[resourceName];
        let title = '';
        if(hashToSchema[this.state.hash_uri] && hashToSchema[this.state.hash_uri].label) title = hashToSchema[this.state.hash_uri].label;

        forms.push(<TableBox key={resourceName} resourceName={resourceName} schema={schema} 
          setCsvQuery={this.setCsvQuery.bind(this)}
          renderers={{
            datei: (datei) => {
              return <a href={global.api_url + 'datei/' + datei.id + '?authorization=' + global.auth.authToken.token + '&binary=true'} target='_blank' rel="noreferrer">Download</a>
          }}}
          ></TableBox>);
      }
    })
   //console.log('global.auth', global.auth);
   //console.log('global hash', window.location.hash.length);

    var title = this.state.contentHeaderTitel ?? this.state.hash_uri;
    if(title in hashToSchema && hashToSchema[title].label){
      title = hashToSchema[title].label
    }else title = global.capitalize(title);
    
    var content;
    if (global.auth === null) {
      content = <LoginPage />;
    } else {
      content = <div>
        <Header />
        <Sidebar adminLinks={Object.keys(this.schemas).map(resourceName => ({ link: '#' + resourceName, label: resourceName }))}></Sidebar>
        <div className="content-wrapper">
          <ContentHeader title={title} quickActions={this.state.contentHeaderTitel ?? this.state.hash_uri} ref={this.csvExport}/>
          <Content>
            {(this.state.hash_uri === 'einstellungen') && <Einstellungen /> }
            {(this.state.hash_uri === 'dashboard' || this.state.hash_uri === '') && <WidgetsArea /> }
            {(this.state.hash_uri === 'vorsorgenkartei') && <ReportMitarbeiterBox schemas={this.schemas} setCsvQuery={this.setCsvQuery.bind(this)} changeTitle={this.changeContentTitle.bind(this)} />}
            {(this.state.hash_uri && this.state.hash_uri.startsWith('new_')) && <FormBox changeTitle={this.changeContentTitle.bind(this)} schemas={this.schemas} hash={this.state.hash_uri} />}
            {forms}
          </Content>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
        <Modal schemas={this.schemas}></Modal>
        <Modal schemas={this.schemas} eventSuffix="_FALLBACK"></Modal>
      </div>;
    }
    return (
      <div>
        {content}
      </div>
    );
  }
}

export default withLogging(App);
